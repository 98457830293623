import React from "react";
// import FeatureViduraSVG from "../../../images/aichapter/feature-vidura.svg";

const FeaturesOfDemos = ({ featuresList }) => {
    return (
        <div className="features-of-demos__grid-item-right">
            {featuresList?.map((list, id) => (
                <div key={id} className="features-of-demos__grid-item-right-content">
                    <div className="features-of-demos__grid-item-right-content__image">
                        <img src={list.imageSrc} alt="Feature-svg" loading="lazy" />
                    </div>
                    <div className="features-of-demos__grid-item-right-content__details">
                        <h3 className="text-h3  title  text-clr-primary text-fw-medium gap-05x">
                            {list.title}
                        </h3>
                        <p className="text-p3 title  text-clr-secondary text-fw-normal">
                            {list.description}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FeaturesOfDemos;
