import React, { useEffect, useRef, useState } from "react";

const CountIncrement = ({ start, end, duration, showPlus = false }) => {
    const [count, setCount] = useState(start);
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting);
            },
            { threshold: 0.5 }
        );
        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    useEffect(() => {
        if (isVisible) {
            const incrementSpeed = ((end - start) / duration) * 500;
            const intervalId = setInterval(() => {
                setCount((prevCount) => {
                    if (prevCount >= end) {
                        clearInterval(intervalId);
                        return end;
                    }
                    return Math.min(prevCount + 1, end);
                });
            }, incrementSpeed);
            return () => clearInterval(intervalId);
        }
    }, [isVisible, start, end, duration]);
    return (
        <div ref={ref}>
            <h1 className="text-h1 AI-common-herostyle__text-description text-fw-medium gap-05x text-clr-white">
                {count}
                {showPlus && count === end ? "+" : "%"}
            </h1>
        </div>
    );
};

export default CountIncrement;
