import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
const ExplainedDemoAI = ({ ListOfExplainAI, btnTitle }) => {
    const [selectedBtnTitle, setSelectedBtnTitle] = useState(ListOfExplainAI[0]);
    const [bgImageLoaded, setBgImageLoaded] = useState(false);
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });
    if (inView && !bgImageLoaded) {
        const img = new Image();
        img.src = selectedBtnTitle.image;
        img.onload = () => setBgImageLoaded(true);
    }
    const handleBtntitleClick = (btnTitle) => {
        setSelectedBtnTitle(btnTitle);
        setBgImageLoaded(false);
    };
    return (
        <>
            <div className="demo-explain-sec-grid">
                <div className="demo-explain-sec-grid__left">
                    {ListOfExplainAI?.map((item, id) => (
                        <div className="demo-explain-sec-grid__left-box" key={id}>
                            <button
                                className={`explain-btn ${
                                    selectedBtnTitle.btnTitle === item.btnTitle ? "active" : ""
                                }`}
                                onClick={() => handleBtntitleClick(item)}
                            >
                                {item.btnTitle}
                            </button>
                        </div>
                    ))}
                </div>
                <div className="demo-explain-sec-grid__right">
                    <div
                        ref={ref}
                        className="content-background"
                        style={{
                            backgroundImage: bgImageLoaded
                                ? `url(${selectedBtnTitle.image})`
                                : "none",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center center",
                            transition: "background-image 0.5s ease-in-out",
                        }}
                    >
                        <div className="content-body">
                            <div className="flex flex-jc-sb gap-1x">
                                <img src={selectedBtnTitle.svgImg} alt="svgImg" loading="lazy" />
                                <span></span>
                            </div>
                            <h2 className="text-h2 text-fw-medium gap-1x">
                                {selectedBtnTitle.title}
                            </h2>
                            <p className="text-p2 text-fw-normal gap-5x">
                                {selectedBtnTitle.content}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default ExplainedDemoAI;
